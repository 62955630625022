<template>
    <v-data-table
        :headers="headers"
        :header-props="headerProps"
        :items="sortedItems"
        :items-per-page="-1"
        no-results-text="Selle filtriga ei leitud ühtegi toodet"
        hide-default-footer
        :height="viewportHeight"
        :mobile-breakpoint="0"
    >
        <template v-slot:item.name="{item}">
            <v-row
                dense
                style="padding-bottom: 2px"
            >
                <v-col cols="12">
                    <div>
                        <div>
                            {{ item.name }}
                        </div>
                        <v-chip
                            v-if="item.groupId && item.groupId.name"
                            x-small
                            class="hidden-md-and-up"
                        >
                            {{ removeGroupOrderTag(item.groupId.name) }}
                        </v-chip>
                    </div>
                </v-col>
            </v-row>
        </template>
        <template v-slot:item.groupId.name="{ item }">
            <v-row
                dense
                style="padding-bottom: 2px"
                class="hidden-sm-and-down"
            >
                <v-col cols="12">
                    <div>
                        <v-chip
                            v-if="item.groupId && item.groupId.name"
                            x-small
                        >
                            {{ removeGroupOrderTag(item.groupId.name) }}
                        </v-chip>
                    </div>
                </v-col>
            </v-row>
        </template>
        <template v-slot:item.amount="{item}">
            <v-row dense>
                <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                >
                    <v-text-field
                        v-model="item.amount"
                        dense
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <template v-slot:no-data>
            Pole tooteid kuvamiseks
        </template>
    </v-data-table>
</template>

<script>

import { removeGroupOrderTag } from "@/js/utils.js";

export default {
    name: "ProductOrderTable",
    props: {
        items: {
            required: true,
            type: Array,
        },
        search: {
            required: true,
            type: String
        },
        selectedShop: {
            required: true,
            type: Object
        }
    },

    data: () => ({
        headerProps: {
            sortByText: "Sorteeri"
        },
        viewportHeight: 0,
    }),

    computed: {
        headers() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return [
                    {
                        text: 'Toode',
                        align: 'start',
                        sortable: true,
                        value: 'name',
                        width: '25%'
                    },
                    {
                        text: 'Kogus',
                        align: 'start',
                        sortable: false,
                        width: '50%',
                        value: 'amount'
                    },
                ]
            }
            return [
                {
                    text: 'Toode',
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    width: '25%'
                },
                {
                    text: 'Grupp',
                    align: 'start',
                    sortable: true,
                    value: 'groupId.name',
                    class: 'hidden-sm-and-down',
                    width: '25%'
                },
                {
                    text: 'Kogus',
                    align: 'start',
                    sortable: false,
                    width: '50%',
                    value: 'amount'
                },
            ]
        },
        computedItems: {
            get() {
                // dirty hack to hide lemmikloom for põlva coop
                if (this.selectedShop.name === 'Põlva Coop') {
                    return this.items.filter(it => removeGroupOrderTag(it.groupId?.name) !== 'Lemmikloom')
                }
                return this.items;
            },
            set(newValue) {
                // Setter emits an event to inform the parent of the change
                this.$emit('update:items', newValue);
            },
        },
        sortedItems() {
            const items = [...this.computedItems].sort((a, b) => {
                if (a.groupId?.name === b.groupId?.name) {
                    return a.name.localeCompare(b.name);
                }
                return a.groupId?.name.localeCompare(b.groupId?.name);
            })

            if (!this.search) {
                return items;
            }
            const search = this.search.toLowerCase();
            return items
                .filter(item => item.name.toLowerCase().includes(search) || item.groupId?.name.toLowerCase().includes(search));
        }
    },

    created() {
        this.updateViewportHeight();
        window.addEventListener('resize', this.updateViewportHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateViewportHeight);
    },

    methods: {
        removeGroupOrderTag,
        updateViewportHeight() {
            this.viewportHeight = window.innerHeight - 30 - 24 - 7 - 91 - 48 -57;
        }
    }
}
</script>

<style scoped lang="scss">

</style>
