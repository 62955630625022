/* eslint-disable no-undef */
import Vue from "vue";
import Router from "vue-router";
import Login from "@/views/Login.vue";
import StoreManagement from "@/views/products/ProductsManagement.vue";
import WorkersManagement from "@/views/workers/WorkersManagement.vue";
import ProductOrder from "@/views/ordering/ProductOrder.vue";
import OrderHistory from "@/views/orderHistory/OrderHistory.vue";
import AdminRegister from "@/views/registration/AdminRegister.vue";
import Groups from "@/views/groups/Groups.vue";
import Stores from "@/views/stores/Stores.vue";

Vue.use(Router);

let router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "Login",
            component: Login
        },
        {
            path: "/admin/tooted",
            name: "Admin tooted",
            component: StoreManagement,
            beforeEnter: (to, from, next) => {
                const userRole = JSON.parse(localStorage.getItem('user'))?.role
                if (localStorage.token && (userRole === 'ADMIN' || userRole === 'EDITOR')) {
                    next();
                } else {
                    localStorage.removeItem("token");
                    localStorage.removeItem('user');
                    next({ name: "Login" });
                }
            }
        },
        {
            path: "/admin/tellimused",
            name: "Admin tellimused",
            component: OrderHistory,
            beforeEnter: (to, from, next) => {
                if (localStorage.token) {
                    next();
                } else {
                    next({ name: "Login" });
                }
            }
        },
        {
            path: "/admin/telli",
            name: "Admin telli",
            props: true,
            component: ProductOrder,
            beforeEnter: (to, from, next) => {
                if (localStorage.token) {
                    next();
                } else {
                    next({ name: "Login" });
                }
            }
        },
        {
            path: "/admin/tootajad",
            name: "Admin workers",
            component: WorkersManagement,
            beforeEnter: (to, from, next) => {
                const userRole = JSON.parse(localStorage.getItem('user'))?.role
                if (localStorage.token && userRole === 'ADMIN') {
                    next();
                } else {
                    localStorage.removeItem("token");
                    localStorage.removeItem('user');
                    next({ name: "Login" });
                }
            }
        },
        {
            path: "/admin/grupid",
            name: "Admin groups",
            component: Groups,
            beforeEnter: (to, from, next) => {
                const userRole = JSON.parse(localStorage.getItem('user'))?.role
                if (localStorage.token && userRole === 'ADMIN') {
                    next();
                } else {
                    localStorage.removeItem("token");
                    localStorage.removeItem('user');
                    next({ name: "Login" });
                }
            }
        },
        {
            path: "/admin/poed",
            name: "Admin stores",
            component: Stores,
            beforeEnter: (to, from, next) => {
                const userRole = JSON.parse(localStorage.getItem('user'))?.role
                if (localStorage.token && userRole === 'ADMIN') {
                    next();
                } else {
                    localStorage.removeItem("token");
                    localStorage.removeItem('user');
                    next({ name: "Login" });
                }
            }
        },
        {
            path: "/admin/register",
            name: "Admin register",
            component: AdminRegister,
            beforeEnter: (to, from, next) => {
                localStorage.removeItem("token");
                localStorage.removeItem('user');
                next();
            }
        }
    ],
    beforeCreate: function () {
        this.$session.destroy();
        if (!this.$session.exists()) {
            this.$router.push("/");
        }
    },
});

router.beforeResolve((to, from, next) => {
    next();
});

export default router;
