<template>
    <v-row
        v-if="!isDataFetchInProgress"
        dense
        class="pa-3"
        justify="center"
    >
        <v-col
            cols="12"
            style="max-width: 1600px"
        >
            <v-card outlined>
                <v-select
                    v-model="selectedShop"
                    class="ma-3"
                    label="Valitud pood"
                    dense
                    :items="userManageableShops"
                    item-text="storeId.name"
                    item-value="storeId"
                    small-chips
                    hide-details
                    deletable-chips
                    :multiple="false"
                    outlined
                ></v-select>
                <div v-if="selectedShop">
                    <v-col
                        cols="12"
                        class="d-flex justify-end"
                    >
                        <v-text-field
                            v-model="search"
                            class="mt-2"
                            label="Filtreeri..."
                            single-line
                            dense
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-divider></v-divider>
                    <ProductOrderTable
                        :items="products"
                        :search="search"
                        :selected-shop="selectedShop"
                    ></ProductOrderTable>
                </div>
                <v-divider v-if="selectedShop"></v-divider>
                <v-card-actions v-if="selectedShop">
                    <v-row>
                        <v-col
                            cols="12"
                            class="d-flex justify-end"
                        >
                            <v-btn
                                class="ml-3"
                                color="primary"
                                :disabled="isButtonDisabled"
                                @click="openOrderConfirmationDialog"
                            >
                                Vormista tellimus
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-row
                    v-else
                    no-gutters
                >
                    <v-col cols="12">
                        <p class="text-center">
                            Poodi pole valitud!
                        </p>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-dialog
            v-if="orderConfirmationDialog"
            v-model="orderConfirmationDialog"
            width="500"
            persistent
        >
            <v-card>
                <v-card-title class="text-h5">
                    Tellimuse ülevaade
                </v-card-title>
                <v-card-text class="mt-1">
                    <v-row dense>
                        <v-col cols="12">
                            <v-textarea
                                v-model="info"
                                outlined
                                label="Lisainfo"
                                dense
                                rows="2"
                                auto-grow
                                hide-details
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <v-card
                                flat
                                outlined
                            >
                                <ul>
                                    <li><b>Pood:</b> {{ selectedShop.name }}</li>
                                    <li v-if="productsForOrder.length !== 0">
                                        <b>Valitud lihapoe tooted:</b>
                                        <ul>
                                            <li
                                                v-for="product in productsForOrder"
                                                :key="product.name"
                                            >
                                                {{ product.name }} - {{ product.amount }}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="isOrderSendingInProgress"
                        @click="closeModal"
                    >
                        Tühista
                    </v-btn>
                    <v-btn
                        color="primary"
                        :loading="isOrderSendingInProgress"
                        @click="sendOrder"
                    >
                        Saada
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <DataFetchLoader v-else></DataFetchLoader>
</template>

<script>
import api from "@/api";
import DataFetchLoader from "@/views/DataFetchLoader.vue";
import ProductOrderTable from "@/views/ordering/ProductOrderTable.vue";
import format from "date-fns/format";
import axios from "axios";
import { removeGroupOrderTag } from "@/js/utils";

export default {
    name: "ProductOrder",
    components: { ProductOrderTable, DataFetchLoader },

    props: {
        existingShop: {
            required: false,
            type: Object,
            default: undefined
        },
        existingProducts: {
            required: false,
            type: Array,
            default:  () => []
        }
    },

    data() {
        return {
            orderConfirmationDialog: false,
            isOrderSendingInProgress: false,
            info: '',
            search: '',
            date: new Date(),
            dateFormat: "yyyy-MM-dd",
            products: [],
            selectedProducts: [],
            selectedShop: '',
            userManageableShops: [],
            isDataFetchInProgress: false
        }
    },

    computed: {
        productsForOrder () {
            return this.products.filter(item => item.amount !== undefined && item.amount !== '' && item.amount !== 0)
        },
        isButtonDisabled () {
            return this.productsForOrder.length === 0
        },
        sortedProductsForOrder () {
            return [...this.productsForOrder].sort((a, b) => {
                if (a.groupId?.name === b.groupId?.name) {
                    return a.name.localeCompare(b.name);
                }
                return a.groupId?.name.localeCompare(b.groupId?.name);
            })
        }
    },

    async mounted() {
        this.userManageableShops = JSON.parse(localStorage.getItem('user')).canManageShops
        this.selectedShop = this.existingShop ?? this.userManageableShops[0].storeId
        await this.fetchProducts()
    },

    methods: {
        async fetchProducts() {
            this.isDataFetchInProgress = true
            this.products = (await api.getProductsForOrder()).response

            if (this.existingProducts?.length > 0) {
                this.products = this.products.map(product => {
                    const existingAmount = this.existingProducts.find(existingProduct => existingProduct.name === product.name)?.amount ?? undefined
                    return {
                        ...product,
                        amount: existingAmount
                    }
                })
            }

            this.isDataFetchInProgress = false
        },

        openOrderConfirmationDialog () {
            this.orderConfirmationDialog = true
        },

        sendOrder () {
            this.isOrderSendingInProgress = true

            let mail = {
                from: "noreply@lihapood.ee",
                to: process.env.VUE_APP_ORDER_TO_ADDRESS,
                subject:
                    "[Lihapood] Tellimus: " +
                    this.selectedShop.name.toLocaleUpperCase() +
                    " - " +
                    format(this.date, this.dateFormat),
                body: this.generateEmailBody(),
            };

            axios
                .post(process.env.VUE_APP_ROOT_API + "/send-email", mail)
                .then((result) => {
                    if (result.data.error) {
                        this.$notify({
                            title: "Saatmine ebaõnnestus!",
                            message: result.data.error,
                            horizontalAlign: "right",
                            verticalAlign: "bottom",
                            type: "danger",
                        });
                    } else {
                        api.createOrder({
                            shop: this.selectedShop._id,
                            date: format(this.date, "yyyy-MM-dd HH:mm:ss"),
                            products: this.productsForOrder.map(product => ({
                                name: product.name,
                                amount: product.amount,
                                groupId: product.groupId
                            }))
                        }).then(() => {
                            this.info = "";
                            this.products.forEach(entry => {
                                this.$set(entry, 'amount', "")
                            })

                            this.$notify({
                                title: "Tellimus saadetud!",
                                text: result.data.message,
                                type: "success",
                            });
                        }).finally(() => {
                            this.closeModal()
                        })
                    }
                })
        },
        closeModal () {
            this.isOrderSendingInProgress = false
            this.orderConfirmationDialog = false
        },
        generateEmailBody: function () {
            let body =
                "<!DOCTYPE html>" +
                "<html lang='et'><head><title>Lihapood Tellimus</title><style>" +
                'table{font-family: "Trebuchet MS", Arial, Helvetica, sans-serif; border-collapse: collapse; width: 50%;} ' +
                "td, th {border: 1px solid #ddd; padding: 8px;}" +
                "</style></head><body>";

            body +=
                "<h2>Pood: " +
                this.selectedShop.name.toLocaleUpperCase() +
                " Lihapood</h2>" +
                "<p><b>Kuup:</b> " + format(this.date, this.dateFormat) + "</p>";

            if (this.info) {
                body += "<p><b>Lisainfo:</b><br>" + this.info + "</p>";
            }

            const groupedByGroupName = this.sortedProductsForOrder.reduce((accumulator, product) => {
                // Get the group name from the product
                const groupName = removeGroupOrderTag(product.groupId?.name) ?? 'Grupeerimata tooted';

                // If the accumulator doesn't already have this group name, add it with an empty array
                if (!accumulator[groupName]) {
                    accumulator[groupName] = [];
                }

                // Add the current product to the group in the accumulator
                accumulator[groupName].push(product);

                return accumulator;
            }, {});

            Object.entries(groupedByGroupName).forEach(([name, values]) => {
                let products = ""
                values.forEach(entry => {
                    if (typeof entry.amount !== "undefined" && entry.amount !== "") {
                        products +=
                            "<tr>" +
                            "<td >" +
                            entry.name +
                            "</td>" +
                            "<td >" +
                            entry.amount +
                            "</td>" +
                            "</tr>";
                    }
                })

                if (products !== "") {
                    body +=
                        `<p><b>${name}: ${this.selectedShop.name.toLocaleUpperCase()}</b></p>` +
                        "<table>" +
                        "<th >Toode</th>" +
                        "<th >Kogus</th>" +
                        products +
                        "</table>";
                }
            })

            return body + "</body></html>";
        },
    }
}
</script>

<style scoped lang="scss">

</style>
