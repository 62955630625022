<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="computedItems"
            :items-per-page="-1"
            hide-default-footer
            :custom-filter="customFilter"
            :search="search"
            sort-by="date"
            no-results-text="Selle filtriga ei leitud ühtegi tellimust"
            :sort-desc="true"
            :height="viewportHeight"
        >
            <template v-slot:item.shop="{item}">
                {{ item.shop.name }}
            </template>
            <template v-slot:item.products="{item}">
                <v-tooltip
                    top
                    max-width="400"
                    open-delay="120"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            small
                            v-bind="attrs"
                            v-on="on"
                        >
                            {{ item.products.length }} toodet tellitud
                        </v-chip>
                    </template>
                    <ul>
                        <li
                            v-for="(product, index) in item.products"
                            :key="index"
                        >
                            <span>{{ product.name }} - {{ product.amount }}</span>
                        </li>
                    </ul>
                </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip
                    top
                    max-width="400"
                    open-delay="120"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="viewOrderDetailsFor(item)"
                        >
                            mdi-eye
                        </v-icon>
                    </template>
                    <span>Vajuta, et näha detailset vaadet</span>
                </v-tooltip>
            </template>
            <template v-slot:no-data>
                Pole tellimusi kuvamiseks
            </template>
        </v-data-table>
        <v-dialog
            v-if="detailViewDialog"
            v-model="detailViewDialog"
            width="75%"
            persistent
        >
            <v-card>
                <v-card-title class="text-h5">
                    Tellimus {{ orderDetails.date }} ({{ orderDetails.shop.name }})
                </v-card-title>
                <v-card-text class="mt-1">
                    <v-row dense>
                        <v-col cols="12">
                            <v-card
                                flat
                                outlined
                            >
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Toode
                                                </th>
                                                <th class="text-left">
                                                    Kogus
                                                </th>
                                                <th class="text-left">
                                                    Grupp
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="product in orderDetails.products"
                                                :key="product.name"
                                            >
                                                <td>{{ product.name }}</td>
                                                <td>{{ product.amount }}</td>
                                                <td>{{ removeGroupOrderTag(product.groupId?.name) }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-row
                        v-if="$vuetify.breakpoint.mobile"
                        dense
                    >
                        <v-col
                            cols="12"
                            class="d-flex flex-column"
                        >
                            <v-btn
                                color="primary"
                                @click="manageSendingOrderAgain"
                            >
                                Saada tellimus uuesti
                            </v-btn>
                            <v-btn
                                class="mt-3"
                                @click="closeModal"
                            >
                                Sulge
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row
                        v-else
                        dense
                    >
                        <v-col
                            cols="12"
                            class="d-flex justify-end"
                        >
                            <v-btn
                                class="mr-3"
                                @click="closeModal"
                            >
                                Sulge
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="manageSendingOrderAgain"
                            >
                                Saada tellimus uuesti
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import { removeGroupOrderTag } from "../../js/utils";

export default {
    name: "OrderHistoryTable",
    components: { },
    props: {
        items: {
            required: true,
            type: Array,
        },
        userManageableShops: {
            required: true,
            type: Array
        },
        search: {
            required: true,
            type: String
        }
    },

    data: () => ({
        detailViewDialog: false,
        headers: [
            { text: 'Kuupäev', value: 'date', sortable: true },
            {
                text: 'Tellija',
                align: 'start',
                sortable: true,
                value: 'name',
            },
            {
                text: 'Pood',
                align: 'start',
                sortable: true,
                value: 'shop',
            },
            {
                text: 'Tellimuse info',
                align: 'start',
                sortable: false,
                value: 'products',
            },
            { text: 'Detailne vaade', value: 'actions', align: 'end', sortable: false },
        ],
        orderDetails: {
            name: '',
            products: [],
            date: '',
            shop: ''
        },
        viewportHeight: 0,
    }),

    computed: {
        computedItems: {
            get() {
                // Getter returns the prop's value
                return this.items.map(it => ({
                    ...it,
                    products: it.products.sort((a, b) => {
                        if (a.groupId?.name === b.groupId?.name) {
                            return a.name.localeCompare(b.name);
                        }
                        return a.groupId?.name.localeCompare(b.groupId?.name);
                    })
                }));
            },
            set(newValue) {
                // Setter emits an event to inform the parent of the change
                this.$emit('update:items', newValue);
            },
        }
    },

    created() {
        this.updateViewportHeight();
        window.addEventListener('resize', this.updateViewportHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateViewportHeight);
    },

    methods: {
        removeGroupOrderTag,
        customFilter (_, search, field) {
            search = search.trim().toLowerCase()

            return [field.date, field.name, ...field.products.flatMap(it => it.name), field.shop.name]
                .map(it => it.toLowerCase())
                .some(it => it.includes(search))
        },
        updateViewportHeight() {
            this.viewportHeight = window.innerHeight - 35 + 3 - 48 - 48;
        },
        viewOrderDetailsFor (item) {
            this.detailViewDialog = true
            this.orderDetails = item
        },

        manageSendingOrderAgain () {
            this.$router.push({
                name: 'Admin telli',
                params: { existingShop: this.orderDetails.shop, existingProducts: this.orderDetails.products },
            })
        },

        closeModal() {
            this.detailViewDialog = false
        }
    }
}
</script>

<style scoped lang="scss">

</style>
