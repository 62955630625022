import { render, staticRenderFns } from "./ProductsTable.vue?vue&type=template&id=26f59df2&scoped=true"
import script from "./ProductsTable.vue?vue&type=script&lang=js"
export * from "./ProductsTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26f59df2",
  null
  
)

export default component.exports