<template>
    <v-app>
        <NavigationDrawer v-if="userIsLoggedIn"></NavigationDrawer>
        <v-main>
            <router-view></router-view>
            <notifications position="bottom right">
                <template v-slot:body="props">
                    <div
                        :class="`vue-notification-template vue-notification ${props.item.type}`"
                        @click="props.close"
                    >
                        <div class="d-flex justify-space-between align-center">
                            <div>
                                <div
                                    class="notification-title"
                                    v-text="props.item.title"
                                ></div>
                                <div
                                    class="notification-content"
                                    v-text="props.item.text"
                                ></div>
                            </div>
                            <div style="cursor: pointer">
                                <v-icon
                                    small
                                    color="white"
                                >
                                    mdi-close-circle
                                </v-icon>
                            </div>
                        </div>
                    </div>
                </template>
            </notifications>
        </v-main>
    </v-app>
</template>

<script>

import NavigationDrawer from "@/views/NavigationDrawer.vue";

export default {
    name: "App",

    components: { NavigationDrawer },
    data () {
        return {
            userIsLoggedIn: false
        }
    },
    watch: {
        // Watch for changes in the login status by listening to the storage event
        '$route': function() {
            this.userIsLoggedIn = !!localStorage.getItem('token');
        }
    },
    created() {
        this.userIsLoggedIn = !!localStorage.getItem('token');
    }
}
</script>
<style>
.background {
    //background-image: linear-gradient(to bottom, #f67f5b, #ea7661, #dc6f66, #cc6869, #bc636b, #ad5b6c, #9d546c, #8c4e6b, #764569, #5f3d66, #453660, #2a2f57);
    background-image: linear-gradient(to bottom, #1d2228, #4f5257, #86878c, #c1c1c4, #ffffff);
}

/* Override tooltip background color */
.v-tooltip__content {
    background-color: rgba(0, 0, 0, 1) !important; /* Solid color */
    color: #FFFFFF !important; /* Adjust text color as needed */
}

body {
    overflow: hidden;
}
</style>
