<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="computedItems"
            :items-per-page="-1"
            hide-default-footer
            fixed-header
            :search="search"
            no-results-text="Selle filtriga ei leitud ühtegi poodi"
            sort-by="role"
            :sort-desc="false"
            :height="viewportHeight"
        >
            <template v-slot:item.actions="{ item }">
                <v-tooltip
                    top
                    max-width="400"
                    open-delay="120"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="editGroup(item)"
                        >
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Muuda grupi informatsiooni</span>
                </v-tooltip>
                <v-tooltip
                    top
                    max-width="400"
                    open-delay="120"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteGroup(item)"
                        >
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Kustuta grupp</span>
                </v-tooltip>
            </template>
            <template v-slot:no-data>
                Pole gruppe kuvamiseks
            </template>
        </v-data-table>
        <v-dialog
            v-if="computedDialogOpen"
            v-model="computedDialogOpen"
            persistent
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="isValidForm">
                        <v-row dense>
                            <v-col
                                cols="12"
                                class="pt-3"
                            >
                                <v-text-field
                                    v-model="editedStore.name"
                                    dense
                                    :rules="[rules.required]"
                                    outlined
                                    label="Nimi"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="isCreateUpdateInProgress"
                        @click="closeModal"
                    >
                        Tühista
                    </v-btn>
                    <v-btn
                        color="primary"
                        :disabled="!isValidForm"
                        :loading="isCreateUpdateInProgress"
                        @click="save"
                    >
                        Salvesta
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogDelete"
            width="500"
            persistent
        >
            <v-card>
                <v-card-title class="text-h5">
                    Oled sa kindel?
                </v-card-title>
                <v-card-subtitle class="mt-1">
                    Grupp nimega {{ editedStore.name }} kustutatakse!
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="isDeleteInProgress"
                        @click="closeDelete"
                    >
                        Ei
                    </v-btn>
                    <v-btn
                        color="primary"
                        :loading="isDeleteInProgress"
                        @click="deleteStoreConfirm"
                    >
                        Jah
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "@/api";

export default {
    name: "StoresTable",

    props: {
        stores: {
            required: true,
            type: Array,
        },
        isDialogOpen: {
            required: true,
            type: Boolean
        },
        search: {
            required: true,
            type: String
        }
    },

    data: () => ({
        isValidForm: false,
        isCreateUpdateInProgress: false,
        dialogDelete: false,
        isDeleteInProgress: false,
        editedIndex: -1,
        editedStore: {
            name: '',
            _id: ''
        },
        defaultStore: {
            name: '',
            _id: ''
        },
        rules: { required: value => !!value || 'Väli peab olema täidetud' },
        headers: [
            {
                text: 'Nimi',
                value: 'name',
                align: 'start',
                sortable: false
            },
            {
                text: 'Toimingud',
                value: 'actions',
                align: 'end',
                sortable: false
            },
        ],
        viewportHeight: 0,
        users: []
    }),

    computed: {
        computedItems: {
            get() {
                return this.stores
            },
            set(newValue) {
                // Setter emits an event to inform the parent of the change
                this.$emit('update:stores', newValue)
            },
        },
        computedDialogOpen: {
            get() {
                return this.isDialogOpen
            },
            set(newValue) {
                this.$emit('update:isDialogOpen', newValue)
            }
        },
        formTitle() {
            return this.editedIndex === -1 ? 'Lisa uus pood' : 'Halda poodi'
        },
    },

    created() {
        this.updateViewportHeight();
        window.addEventListener('resize', this.updateViewportHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateViewportHeight);
    },

    methods: {
        updateViewportHeight() {
            this.viewportHeight = window.innerHeight - 48 - 35 - 48;
        },
        editGroup(item) {
            this.editedStore = Object.assign({}, item)
            this.editedIndex = this.computedItems.indexOf(item)
            this.computedDialogOpen = true
        },

        closeModal() {
            this.isCreateUpdateInProgress = false
            this.computedDialogOpen = false
            this.$nextTick(() => {
                this.editedStore = Object.assign({}, this.defaultStore)
                this.editedIndex = -1
            })
        },
        async save() {
            if (this.editedIndex === -1) {
                await this.createStore()
                return
            }

            await this.updateStore()
        },

        async createStore() {
            this.isCreateUpdateInProgress = true
            const response = (await api.createStore({ name: this.editedStore.name })).response
            this.$notify({
                title: "Poe lisamine õnnestus",
                type: "success",
            })

            this.computedItems.push(response)
            this.closeModal()
        },

        async updateStore() {
            this.isCreateUpdateInProgress = true
            await api.updateStore(this.editedStore._id, this.editedStore)
            this.$notify({
                title: "Poe uuendamine õnnestus",
                type: "success",
            })

            this.$set(this.computedItems[this.editedIndex], 'name', this.editedStore.name)
            this.closeModal()
        },

        async deleteGroup(group) {
            this.editedIndex = this.stores.findIndex(it => it._id === group._id)
            this.editedStore = Object.assign({}, group)
            this.dialogDelete = true
        },

        async deleteStoreConfirm() {
            this.isDeleteInProgress = true
            await api.deleteStore(this.editedStore._id)
            this.$notify({
                title: "Poe kustutamine õnnestus",
                type: "success",
            })

            // TODO: consider fixing this if have time
            // eslint-disable-next-line vue/no-mutating-props
            this.stores.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        closeDelete() {
            this.dialogDelete = false
            this.isDeleteInProgress = false

            this.$nextTick(() => {
                this.editedStore = Object.assign({}, this.defaultStore)
                this.editedIndex = -1
            })
        }
    }
}
</script>

<style scoped lang="scss">

</style>
