<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="computedItems"
            :items-per-page="-1"
            hide-default-footer
            fixed-header
            :search="search"
            :custom-filter="customFilter"
            sort-by="role"
            no-results-text="Selle filtriga ei leitud ühtegi kasutajat"
            :sort-desc="false"
            :height="viewportHeight"
        >
            <template v-slot:item.canManageShops="{ item }">
                <v-chip
                    v-for="(shop, index) in item.canManageShops"
                    :key="index"
                    class="mr-1 my-1"
                    small
                >
                    {{ shop.storeId.name }}
                </v-chip>
            </template>
            <template v-slot:item.role="{ item }">
                <v-chip small>
                    {{ item.role }}
                </v-chip>
            </template>
            <template v-slot:item.userType="{ item }">
                <v-tooltip
                    v-if="item.userType"
                    top
                    max-width="400"
                    open-delay="120"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            small
                            :color="item.userType === 'AKTIIVNE' ? 'success' : 'info'"
                            v-bind="attrs"
                            v-on="on"
                        >
                            {{ item.userType }}
                        </v-chip>
                    </template>
                    <span>{{ item.userType === 'AKTIIVNE' ? 'Kasutaja on aktiveeritud' : 'Kasutaja on registreerimise lingi kätte saanud' }}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip
                    top
                    max-width="400"
                    open-delay="120"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="editUser(item)"
                        >
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Muuda kasutaja informatsiooni</span>
                </v-tooltip>
                <v-tooltip
                    top
                    max-width="400"
                    open-delay="120"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteUser(item)"
                        >
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Kustuta kasutaja</span>
                </v-tooltip>
            </template>
            <template v-slot:no-data>
                Pole kasutajaid
            </template>
        </v-data-table>
        <v-dialog
            v-if="computedDialogOpen"
            v-model="computedDialogOpen"
            persistent
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="isValidForm">
                        <v-row dense>
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <v-text-field
                                    v-model="editedUser.firstName"
                                    dense
                                    :rules="[rules.required]"
                                    outlined
                                    label="Eesnimi"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <v-text-field
                                    v-model="editedUser.lastName"
                                    dense
                                    :rules="[rules.required]"
                                    outlined
                                    label="Perekonnanimi"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                class="pt-0"
                            >
                                <v-text-field
                                    v-model="editedUser.username"
                                    dense
                                    :rules="[rules.required]"
                                    outlined
                                    label="Kasutajanimi"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                class="pt-0"
                            >
                                <v-text-field
                                    v-model="editedUser.email"
                                    dense
                                    :rules="[rules.required]"
                                    outlined
                                    label="Email"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                class="pt-0"
                            >
                                <v-select
                                    v-model="editedUser.role"
                                    :items="['VIEWER', 'EDITOR', 'ADMIN']"
                                    dense
                                    :rules="[rules.required]"
                                    outlined
                                    :multiple="false"
                                    deletable-chips
                                    small-chips
                                    label="Roll"
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                class="pt-0"
                            >
                                <v-select
                                    v-model="editedUser.canManageShops"
                                    :items="stores"
                                    :rules="[rules.canNotBeEmpty]"
                                    outlined
                                    multiple
                                    item-text="storeId.name"
                                    item-value="storeId._id"
                                    deletable-chips
                                    small-chips
                                    label="Seotud poed"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="isCreateUpdateInProgress"
                        @click="closeModal"
                    >
                        Tühista
                    </v-btn>
                    <v-btn
                        color="primary"
                        :disabled="!isValidForm"
                        :loading="isCreateUpdateInProgress"
                        @click="save"
                    >
                        Salvesta
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogDelete"
            width="500"
            persistent
        >
            <v-card>
                <v-card-title class="text-h5">
                    Oled sa kindel?
                </v-card-title>
                <v-card-subtitle class="mt-1">
                    Kasutajanimega "{{ editedUser.username }}" kasutaja kustutatakse!
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="isDeleteInProgress"
                        @click="closeDelete"
                    >
                        Ei
                    </v-btn>
                    <v-btn
                        color="primary"
                        :loading="isDeleteInProgress"
                        @click="deleteUserConfirm"
                    >
                        Jah
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "@/api";

export default {
    name: "WorkersTable",

    props: {
        items: {
            required: true,
            type: Array,
        },
        stores: {
            required: true,
            type: Array,
        },
        isDialogOpen: {
            required: true,
            type: Boolean
        },
        search: {
            required: true,
            type: String
        }
    },

    data: () => ({
        isValidForm: false,
        isCreateUpdateInProgress: false,
        dialogDelete: false,
        isDeleteInProgress: false,
        editedIndex: -1,
        editedUser: {
            firstName: '',
            lastName: '',
            email: '',
            canManageShops: [],
            username: '',
            role: '',
            _id: ''
        },
        defaultUser: {
            firstName: '',
            lastName: '',
            email: '',
            username: '',
            canManageShops: [],
            role: '',
            _id: ''
        },
        rules: {
            required: value => !!value || 'Väli peab olema täidetud',
            canNotBeEmpty: value => value !== null && value.length !== 0 || 'Väli peab olema täidetud'
        },
        headers: [
            {
                text: 'Kasutajanimi',
                value: 'username',
                align: 'start',
                sortable: false
            },
            {
                text: 'Roll',
                value: 'role',
                align: 'start',
                sortable: true
            },
            {
                text: 'Hallatavad poed',
                value: 'canManageShops',
                align: '',
                sortable: false,
                class: 'text-no-wrap',
                width: '100%'
            },
            {
                text: 'Staatus',
                value: 'userType',
                align: 'start',
                sortable: false
            },
            {
                text: 'Toimingud',
                value: 'actions',
                align: 'start',
                sortable: false
            },
        ],
        viewportHeight: 0,
        users: []
    }),

    computed: {
        computedItems: {
            get() {
                // Getter returns the prop's value
                return this.items.map(item => ({
                    ...item,
                    canManageShops: item.canManageShops.sort()
                }))
            },
            set(newValue) {
                // Setter emits an event to inform the parent of the change
                this.$emit('update:items', newValue)
            },
        },
        computedDialogOpen: {
            get () {
                return this.isDialogOpen
            },
            set(newValue) {
                this.$emit('update:isDialogOpen', newValue)
            }
        },
        formTitle () {
            return this.editedIndex === -1 ? 'Lisa uus kasutaja' : 'Halda kasutajat'
        },
    },

    created() {
        this.updateViewportHeight();
        window.addEventListener('resize', this.updateViewportHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateViewportHeight);
    },

    methods: {
        customFilter (_, search, field) {
            search = search.trim().toLowerCase()

            return [field.username, field.role, ...field.canManageShops.flatMap(it => it.storeId.name), field.userType]
                .map(it => it.toLowerCase())
                .some(it => it.includes(search))
        },
        updateViewportHeight() {
            this.viewportHeight = window.innerHeight - 48 - 35 - 48;
        },
        editUser (item) {
            this.editedUser = Object.assign({}, item)
            this.editedIndex = this.computedItems.indexOf(item)
            this.computedDialogOpen = true
        },

        closeModal() {
            this.isCreateUpdateInProgress = false
            this.computedDialogOpen = false
            this.$nextTick(() => {
                this.editedUser = Object.assign({}, this.defaultUser)
                this.editedIndex = -1
            })
        },
        async save () {
            if (this.editedIndex === -1) {
                await this.sendInviteLink()
                return
            }

            await this.updateExistingUser()
        },

        async sendInviteLink () {
            const axios = require("axios");
            this.isCreateUpdateInProgress = true

            const user = await api.inviteUser({
                firstName: this.editedUser.firstName,
                lastName: this.editedUser.lastName,
                email: this.editedUser.email,
                username: this.editedUser.username,
                canManageShops: this.editedUser.canManageShops,
                role: this.editedUser.role
            }).catch(() => {
                this.$notify({
                    title: "Kasutaja registreerimine ebaõnnestus",
                    text: "Kasutaja email on juba kasutuses",
                    type: "error",
                })
            })

            if (user === undefined) {
                this.closeModal()
                return
            }

            const mail = {
                from: "noreply@lihapood.ee",
                to: user.response.email,
                subject: "Lihapoe kasutajaks registreerimine",
                body: this.generateEmailBody(user.response)
            }

            axios
                .post(process.env.VUE_APP_ROOT_API + "/send-email", mail)
                .then((result) => {
                    if (result.data.error) {
                        this.$notify({
                            title: "Saatmine ebaõnnestus!",
                            message: result.data.error,
                            horizontalAlign: "right",
                            verticalAlign: "bottom",
                            type: "danger",
                        })
                    }
                    this.computedItems = [...this.computedItems, user.response]
                    this.$notify({
                        title: "Kasutaja registreerimine õnnestus",
                        text: `${user.response.email} emailile saadeti juhised, et registreerimine lõpuni viia`,
                        type: "success",
                    })
                }).finally(() => {
                    this.closeModal()
                })
        },

        generateEmailBody: function (createdUser) {
            let body =
                "<!DOCTYPE html>" +
                "<html><head></head><body>"


            body += `<p>Tere ${createdUser.firstName} ${createdUser.lastName},<br></p>`
            body += `<p>Teile on loodud kasutaja Lihapoe keskkonnas. Palun viige registreerimine lõpuni <a href="${window.location.origin}/admin/register?code=${createdUser.regCode}">siit</a><br></p>`
            body += `<p>Lugupidamisega <br> Lihapood</p>`

            return body + "</body></html>";
        },

        async updateExistingUser () {
            this.isCreateUpdateInProgress = true
            const user = {
                ...this.editedUser,
                canManageShops: this.editedUser.canManageShops.map(it => it.storeId?._id ?? it)
            }
            await api.updateUser(this.editedUser._id, user)
            this.$notify({
                title: "Kasutaja uuendamine õnnestus",
                type: "success",
            })

            Object.assign(this.computedItems[this.editedIndex], {
                ...this.editedUser,
                canManageShops: user.canManageShops.map(it => {
                    const foundStore = this.stores.find(store => store.storeId._id === it).storeId.name
                    return {
                        storeId: {
                            _id: it,
                            name: foundStore
                        }
                    }
                })
            })
            this.closeModal()
        },

        async deleteUser (user) {
            this.editedIndex = this.items.findIndex(it => it._id === user._id)
            this.editedUser = Object.assign({}, user)
            this.dialogDelete = true
        },

        async deleteUserConfirm () {
            this.isDeleteInProgress = true
            await api.deleteUser(this.editedUser._id)
            this.$notify({
                title: "Kasutaja kustutamine õnnestus",
                type: "success",
            })

            // TODO: consider fixing this if have time
            // eslint-disable-next-line vue/no-mutating-props
            this.items.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        closeDelete () {
            this.dialogDelete = false
            this.isDeleteInProgress = false

            this.$nextTick(() => {
                this.editedUser = Object.assign({}, this.defaultUser)
                this.editedIndex = -1
            })
        }
    }
}
</script>

<style scoped lang="scss">

</style>
