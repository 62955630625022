<template>
    <v-row
        v-if="!isDataFetchInProgress"
        dense
        class="pa-3"
        justify="center"
    >
        <v-col
            cols="12"
            style="max-width: 1600px"
        >
            <v-card outlined>
                <OrderHistoryTable
                    :items="orders"
                    :user-manageable-shops="userManageableShops"
                    :search="search"
                ></OrderHistoryTable>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="search"
                                class="mt-2"
                                label="Otsi poode..."
                                single-line
                                dense
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <DataFetchLoader v-else></DataFetchLoader>
</template>

<script>
import api from "@/api";
import DataFetchLoader from "@/views/DataFetchLoader.vue";
import OrderHistoryTable from "@/views/orderHistory/OrderHistoryTable.vue";

export default {
    name: "OrderHistory",
    components: { OrderHistoryTable, DataFetchLoader },

    data() {
        return {
            tab: 0,
            orders: [],
            userManageableShops: [],
            search: '',
            isDataFetchInProgress: false
        }
    },

    watch: {
        async userManageableShops() {
            if (this.userManageableShops) {
                this.isDataFetchInProgress = true
                await this.fetchOrders().finally(() => {
                    this.isDataFetchInProgress = false
                })
            }
        }
    },

    async mounted() {
        this.userManageableShops = JSON.parse(localStorage.getItem('user')).canManageShops
    },

    methods: {
        async fetchOrders() {
            this.orders = (await api.getOrderHistory()).response
        }
    }
}
</script>

<style scoped lang="scss">

</style>
