<template>
    <v-container
        fluid
        fill-height
        class="background"
        @keyup.enter="enterPressed"
    >
        <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <v-card class="pa-5">
                    <v-card-title class="justify-center primary--text">
                        ADMINISTREERIMISLIIDES
                    </v-card-title>
                    <v-form v-model="isValid">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="username"
                                    outlined
                                    :rules="rules"
                                    label="Kasutajanimi"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="password"
                                    outlined
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showPassword ? 'text' : 'password'"
                                    :rules="rules"
                                    label="Parool"
                                    @click:append="showPassword = !showPassword"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row
                        justify="center"
                        class="pt-0"
                    >
                        <v-col cols="12">
                            <div class="text-center">
                                <v-btn
                                    color="primary"
                                    :disabled="!isValid"
                                    :loading="isLoginInProcess"
                                    @click="login"
                                >
                                    LOGI SISSE
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-container>
</template>

<script>
import api from "@/api";

export default {
    name: "Login",

    data() {
        return {
            username: null,
            isValid: false,
            password: null,
            showPassword: false,
            rules: [v => !!v || 'Väli peab olema täidetud'],
            isLoginInProcess: false
        };
    },

    mounted() {
        if (localStorage.token) {
            this.$router.push("/admin/telli");
        }
    },

    methods: {
        async enterPressed () {
            if (!this.isValid) {
                return
            }

            await this.login()
        },
        async login() {
            this.isLoginInProcess = true

            let response = await api.login({
                username: this.username,
                password: this.password
            });

            this.checkLogin(response);
        },
        checkLogin(response) {
            this.isLoginInProcess = false
            //fail
            if (response && response.error) {
                this.$notify({
                    title: "Sisselogimine ebaõnnestus",
                    text: response.error,
                    type: "error",
                });

                // success
            } else if (response && response.access_token && response.user) {
                localStorage.token = response.access_token;
                localStorage.user = JSON.stringify({
                    canManageShops: response.user.canManageShops,
                    role: response.user.role
                })
                this.$router.push("/admin/telli");

                this.$notify({
                    title: "Sisselogimine õnnestus",
                    text: "Tere " + response.user.firstName + "!",
                    type: "success",
                });
            }
        },
    },
}
</script>

<style scoped lang="scss">

</style>
