<template>
    <v-row
        class="text-center fill-height"
        no-gutters
        align="center"
        justify="center"
    >
        <v-col
            cols="12"
            align-self="end"
        >
            <v-progress-circular
                :indeterminate="indeterminate"
                :value="value"
                :size="size"
                :color="color"
            ></v-progress-circular>
        </v-col>
        <v-col
            cols="12"
            align-self="start"
        >
            <h3> {{ text }}</h3>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "DataFetchLoader",
    props: {
        color: {
            type: String,
            required: false,
            default: ''
        },
        size: {
            type: [String, Number],
            required: false,
            default: 100
        },
        text: {
            type: String,
            required: false,
            default: 'Laen andmeid...'
        },
        indeterminate: {
            type: Boolean,
            required: false,
            default: true
        },
        value: {
            type: Number,
            required: false,
            default: 0
        }
    }
}
</script>

<style scoped lang="scss">

</style>
