/* eslint-disable no-undef */
import axios from "axios";

const client = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    json: true,
});

export default {
    async execute(method, resource, data) {
        return client({
            method,
            url: resource,
            data
        }).then((req) => {
            return req.data;
        });
    },
    async authExecute(method, resource, data, params) {
        if (!localStorage.token) {
            window.location = "/";
        }

        // inject the accessToken for each request
        let accessToken = localStorage.token;
        return client({
            method,
            url: resource,
            data,
            headers: {
                Authorization: `${accessToken}`,
            },
            params,
        }).then((req) => {
            if (!req.data.success) {
                localStorage.removeItem("token");
                localStorage.removeItem('user');
                window.location = "/";
            } else {
                return req.data;
            }
        });
    },

    //orderProducts
    getProductsForOrder() {
        return this.authExecute("get", `/products`, null, );
    },
    getProductsForManagement() {
        return this.authExecute("get", '/products/manage');
    },
    createProduct(data) {
        return this.authExecute("post", "/products", data);
    },
    updateProduct(id, data) {
        return this.authExecute("put", `/products/${id}`, data);
    },
    deleteProduct(id) {
        return this.authExecute("delete", `/products/${id}`);
    },

    // ordersHistory
    getOrderHistory () {
        return this.authExecute("get", '/orders', null);
    },

    createOrder (data) {
        return this.authExecute("post", '/orders', data)
    },

    // session
    login(data) {
        return this.execute("post", "/login", data);
    },
    logout() {
        return this.execute("get", "/logout");
    },

    // users
    fetchUser () {
        return this.authExecute("get", "/user")
    },

    fetchUsers () {
        return this.authExecute("get", "/users")
    },

    inviteUser(data) {
        return this.authExecute("post", "/users/invite", data);
    },

    confirmUser(data) {
        return this.execute("put", "/users/confirm", data)
    },

    updateUser(id, data) {
        return this.authExecute("put", `/users/${id}`, data);
    },

    updatePersonalUser (data) {
        return this.authExecute("put", `/user`, data);
    },

    deleteUser(id) {
        return this.authExecute("delete", `/users/${id}`);
    },

    // groups
    fetchGroups () {
        return this.authExecute("get", "/groups")
    },

    createGroup (data) {
        return this.authExecute("post", "/groups", data)
    },

    updateGroup (id, data) {
        return this.authExecute("put", `/groups/${id}`, data)
    },

    deleteGroup (id) {
        return this.authExecute("delete", `/groups/${id}`)
    },

    // stores
    fetchStores () {
        return this.authExecute("get", "/stores")
    },

    createStore (data) {
        return this.authExecute("post", "/stores", data)
    },

    updateStore (id, data) {
        return this.authExecute("put", `/stores/${id}`, data)
    },

    deleteStore (id) {
        return this.authExecute("delete", `/stores/${id}`)
    }
};
