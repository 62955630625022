<template>
    <v-row
        v-if="!isDataFetchInProgress"
        class="pa-3"
        dense
        justify="center"
    >
        <v-col
            cols="12"
            style="max-width: 1600px"
        >
            <v-card outlined>
                <StoresTable
                    :stores.sync="stores"
                    :is-dialog-open.sync="isDialogOpen"
                    :search="search"
                ></StoresTable>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-row>
                        <v-col
                            cols="12"
                            class="d-flex justify-end"
                        >
                            <v-text-field
                                v-model="search"
                                class="mt-2"
                                label="Otsi poode..."
                                single-line
                                dense
                                hide-details
                            ></v-text-field>
                            <v-btn
                                class="ml-3"
                                color="primary"
                                @click="openAddNewStoreDialog"
                            >
                                Lisa uus pood
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <DataFetchLoader v-else></DataFetchLoader>
</template>

<script>
import api from "@/api";
import DataFetchLoader from "@/views/DataFetchLoader.vue";
import StoresTable from "@/views/stores/StoresTable.vue";

export default {
    name: "Stores",
    components: { StoresTable, DataFetchLoader },
    data () {
        return {
            isDataFetchInProgress: false,
            isDialogOpen: false,
            stores: [],
            search: ''
        }
    },

    async mounted () {
        this.isDataFetchInProgress = true
        this.stores = (await api.fetchStores()).response
        this.isDataFetchInProgress = false
    },

    methods: {
        openAddNewStoreDialog () {
            this.isDialogOpen = true
        }
    }
}
</script>

<style scoped lang="scss">

</style>
