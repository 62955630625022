<template>
    <v-row
        v-if="!isDataFetchInProgress"
        class="pa-3"
        dense
        justify="center"
    >
        <v-col
            cols="12"
            style="max-width: 1600px"
        >
            <v-card outlined>
                <WorkersTable
                    :items.sync="users"
                    :stores="stores"
                    :is-dialog-open.sync="isDialogOpen"
                    :search="search"
                ></WorkersTable>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-row>
                        <v-col
                            cols="12"
                            class="d-flex justify-end"
                        >
                            <v-text-field
                                v-model="search"
                                class="mt-2"
                                label="Otsi kasutajaid..."
                                single-line
                                dense
                                hide-details
                            ></v-text-field>
                            <v-btn
                                class="ml-3"
                                color="primary"
                                @click="openAddNewUserDialog"
                            >
                                Lisa uus kasutaja
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <DataFetchLoader v-else></DataFetchLoader>
</template>

<script>
import api from "@/api";
import WorkersTable from "@/views/workers/WorkersTable.vue";
import DataFetchLoader from "@/views/DataFetchLoader.vue";

export default {
    name: "WorkersManagement",
    components: { DataFetchLoader, WorkersTable },
    data () {
        return {
            isDataFetchInProgress: false,
            isDialogOpen: false,
            stores: [],
            users: [],
            search: ''
        }
    },

    async mounted () {
        this.isDataFetchInProgress = true
        this.stores = (await api.fetchStores()).response.sort().map(it => ({
            storeId: {
                _id: it._id,
                name: it.name
            }
        }))
        this.users = (await api.fetchUsers()).response
        this.isDataFetchInProgress = false
    },

    methods: {
        openAddNewUserDialog () {
            this.isDialogOpen = true
        }
    }
}
</script>

<style scoped lang="scss">

</style>
