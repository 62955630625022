<template>
    <div>
        <v-app-bar
            app
            fixed
            dense
            clipped-left
        >
            <v-btn
                icon
                @click.native.stop="mini = !mini"
            >
                <v-icon>mdi-menu</v-icon>
            </v-btn>
            <v-toolbar-title class="title">
                Lihapood
            </v-toolbar-title>
        </v-app-bar>
        <v-navigation-drawer
            v-model="mini"
            app
            color="primary"
            clipped
        >
            <v-list
                dense
                nav
            >
                <v-list-item-group>
                    <v-list-item
                        v-for="item in visibleRoutes"
                        :key="item.title"
                        link
                        active-class="activeClass"
                        :to="item.route"
                    >
                        <v-list-item-icon>
                            <v-icon color="white">
                                {{ item.icon }}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="white--text">
                            <v-list-item-title>
                                {{ item.title }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <v-divider style="background-color: white; opacity: 50%"></v-divider>
            <template v-slot:append>
                <v-row class="px-2 pb-2 justify-center text-center">
                    <v-col
                        cols="12"
                        class="py-0"
                    ></v-col>
                    <v-col cols="12">
                        <v-btn
                            :loading="logoutInProgress"
                            @click="logout"
                        >
                            Logi välja
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <v-dialog
                v-if="dialogOpen"
                v-model="dialogOpen"
                persistent
                max-width="500px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Halda kasutajat</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form v-model="isValidForm">
                            <v-row dense>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-text-field
                                        v-model="user.firstName"
                                        dense
                                        :rules="[rules.required]"
                                        outlined
                                        label="Eesnimi"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-text-field
                                        v-model="user.lastName"
                                        dense
                                        :rules="[rules.required]"
                                        outlined
                                        label="Perekonnanimi"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="pt-0"
                                >
                                    <v-text-field
                                        v-model="user.email"
                                        dense
                                        :rules="[rules.required]"
                                        outlined
                                        label="Email"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            :loading="isUpdateInProgress"
                            @click="closeModal"
                        >
                            Tühista
                        </v-btn>
                        <v-btn
                            color="primary"
                            :disabled="!isValidForm"
                            :loading="isUpdateInProgress"
                            @click="save"
                        >
                            Salvesta
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-navigation-drawer>
    </div>
</template>

<script>
import api from "@/api";

export default {
    name: "NavigationDrawer",
    data() {
        return {
            mini: false,
            logoutInProgress: false,
            isUpdateInProgress: false,
            isDataFetchInProgress: false,
            dialogOpen: false,
            user: null,
            isValidForm: false,
            items: [
                {
                    title: "Telli tooteid",
                    icon: 'mdi-truck-fast-outline',
                    route: '/admin/telli',
                    accessRight: ['VIEWER', 'EDITOR', 'ADMIN']
                },
                {
                    title: "Halda tooteid",
                    icon: 'mdi-package-variant-closed',
                    route: '/admin/tooted',
                    accessRight: ['ADMIN','EDITOR']
                },
                {
                    title: "Halda poenimekirja",
                    icon: 'mdi-store-outline',
                    route: '/admin/poed',
                    accessRight: ['ADMIN']
                },
                {
                    title: "Halda tootegruppe",
                    icon: 'mdi-group',
                    route: '/admin/grupid',
                    accessRight: ['ADMIN']
                },
                {
                    title: "Halda kasutajaid",
                    icon: 'mdi-account-outline',
                    route: '/admin/tootajad',
                    accessRight: ['ADMIN']
                },
                {
                    title: "Tellimuste ajalugu",
                    icon: 'mdi-history',
                    route: '/admin/tellimused',
                    accessRight: ['VIEWER', 'EDITOR', 'ADMIN']
                }
            ],
            rules: {
                required: value => !!value || 'Väli peab olema täidetud'
            },
            right: null,
        };
    },

    computed: {
        visibleRoutes () {
            const userRole = JSON.parse(localStorage.getItem('user')).role

            return this.items.filter(route => route.accessRight.includes(userRole))

        }
    },

    mounted () {
        this.mini = !this.$vuetify.breakpoint.mobile
    },

    methods: {
        async logout () {
            this.logoutInProgress = true
            await api.logout().finally(() => {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                window.location = "/";
                this.logoutInProgress = false
            })
        },

        async save () {
            this.isUpdateInProgress = true
            await api.updatePersonalUser(this.user)
                .finally(() => this.isUpdateInProgress = false)

            this.$notify({
                title: "Kasutaja uuendamine õnnestus",
                type: "success",
            })
            this.closeModal()
        },

        closeModal () {
            this.dialogOpen = false
        }
    }
};
</script>

<style scoped lang="scss">
.activeClass {
    background-color: gray;
}
</style>
