<template>
    <v-container
        fluid
        fill-height
        class="background"
    >
        <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <v-card class="pa-5">
                    <v-card-title
                        v-if="!userConfirmationFinished"
                        class="justify-center primary--text"
                    >
                        LIHAPOE KASUTAJAKS REGISTREERIMINE
                    </v-card-title>
                    <div v-if="!userConfirmationFinished">
                        <v-form v-model="valid">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="initialPassword"
                                        outlined
                                        :append-icon="showInitialPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="showInitialPassword ? 'text' : 'password'"
                                        :rules="passwordRules"
                                        dense
                                        label="Parool"
                                        @click:append="showInitialPassword = !showInitialPassword"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="repeatPassword"
                                        outlined
                                        dense
                                        :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="showRepeatPassword ? 'text' : 'password'"
                                        :rules="passwordConfirmRules"
                                        label="Korda parooli"
                                        @click:append="showRepeatPassword = !showRepeatPassword"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row
                            justify="center"
                            class="pt-0"
                        >
                            <v-col cols="12">
                                <div class="text-center">
                                    <v-btn
                                        color="primary"
                                        :disabled="!valid"
                                        :loading="isRegisterInProgress"
                                        @click="register"
                                    >
                                        REGISTREERI
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else>
                        <v-row
                            justify="center"
                            class="pt-0"
                        >
                            <v-col cols="12">
                                <div class="text-center">
                                    <v-icon
                                        x-large
                                        color="primary"
                                    >
                                        mdi-check-circle
                                    </v-icon>
                                    <p>Kasutaja edukalt registreeritud!</p>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="text-center">
                                    <v-btn
                                        color="primary"
                                        @click="goToLogin"
                                    >
                                        Mine sisse logima
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-container>
</template>

<script>
import api from "@/api";

export default {
    name: "AdminRegister",
    data: () => ({
        initialPassword: '',
        repeatPassword: '',
        regCode: '',
        showInitialPassword: false,
        showRepeatPassword: false,
        isRegisterInProgress: false,
        valid: false,
        userConfirmationFinished: false,
        passwordRules: [
            v => !!v || 'Väli peab olema täidetud',
            v => v.length >= 8 || 'Parool peab olema vähemalt kaheksa tähte pikk',
            v => /[A-Z]/.test(v) || 'Parool peab sisaldama vähemalt ühte suurt tähte',
            v => /[a-z]/.test(v) || 'Parool peab sisaldama vähemalt ühte väikest tähte',
            v => /[0-9]/.test(v) || 'Parool peab sisaldama vähemalt ühte numbrit',
        ],

    }),

    computed: {
        passwordConfirmRules() {
            return [
                v => !!v || 'Väli peab olema täidetud',
                v => v === this.initialPassword || 'Paroolid peavad olema samasugused',
            ];
        }
    },

    mounted () {
        this.regCode = this.$route.query.code
    },

    methods: {
        async register () {
            this.isRegisterInProgress = true
            await api.confirmUser({
                regCode: this.regCode,
                password: this.initialPassword
            }).finally(() => {
                this.isRegisterInProgress = false
                this.userConfirmationFinished = true
            })
        },

        goToLogin () {
            this.$router.push("/")
        }
    }

}
</script>

<style scoped lang="scss">

</style>
