<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="sortedItems"
            :items-per-page="-1"
            no-results-text="Selle filtriga ei leitud ühtegi toodet"
            hide-default-footer
            fixed-header
            :mobile-breakpoint="0"
            :height="viewportHeight"
        >
            <template v-slot:header>
                <v-row class="px-3 py-2">
                    <v-col
                        cols="12"
                        class="d-flex justify-end"
                    >
                        <v-text-field
                            v-model="search"
                            class="mt-2"
                            label="Filtreeri..."
                            single-line
                            dense
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip
                    top
                    max-width="400"
                    open-delay="120"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="editItem(item)"
                        >
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Vajuta, et muuta toodet</span>
                </v-tooltip>
                <v-tooltip
                    top
                    open-delay="120"
                    max-width="400"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteItem(item)"
                        >
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Vajuta, et kustutada toode</span>
                </v-tooltip>
            </template>
            <template v-slot:item.groupId="{item}">
                <v-chip
                    v-if="item.groupId?.name"
                    small
                >
                    {{ removeGroupOrderTag(item.groupId.name) }}
                </v-chip>
            </template>
            <template v-slot:footer>
                <v-divider></v-divider>
                <v-row class="px-3 py-2">
                    <v-col
                        cols="12"
                        class="d-flex justify-end"
                    >
                        <v-btn
                            color="primary"
                            class="ml-3"
                            @click="openCreateModal"
                        >
                            Lisa uus toode
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                Pole tooteid kuvamiseks
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialogDelete"
            width="500"
            persistent
        >
            <v-card>
                <v-card-title class="text-h5">
                    Oled sa kindel?
                </v-card-title>
                <v-card-subtitle class="mt-1">
                    Toode nimega {{ editedItem.name }} kustutatakse!
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="isDeleteInProgress"
                        @click="closeDelete"
                    >
                        Ei
                    </v-btn>
                    <v-btn
                        color="primary"
                        :loading="isDeleteInProgress"
                        @click="deleteItemConfirm"
                    >
                        Jah
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-form v-model="isValidForm">
                        <v-row dense>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="editedItem.name"
                                    dense
                                    :rules="[rules.required]"
                                    outlined
                                    label="Nimi"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                class="pt-0"
                            >
                                <v-select
                                    v-model="editedItem.groupId"
                                    :items="groups"
                                    dense
                                    :rules="[rules.required]"
                                    item-text="name"
                                    item-value="_id"
                                    outlined
                                    small-chips
                                    deletable-chips
                                    label="Toote grupp"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="isCreateUpdateInProgress"
                        @click="close"
                    >
                        Tühista
                    </v-btn>
                    <v-btn
                        color="primary"
                        :disabled="!isValidForm"
                        :loading="isCreateUpdateInProgress"
                        @click="save"
                    >
                        Salvesta
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "@/api";
import { removeGroupOrderTag } from "../../js/utils";

export default {
    name: "ProductsTable",
    props: {
        items: {
            required: true,
            type: Array,
        },
        groups: {
            required: true,
            type: Array
        }
    },

    data: () => ({
        dialog: false,
        dialogDelete: false,
        isValidForm: false,
        isCreateUpdateInProgress: false,
        isDeleteInProgress: false,
        search: '',
        rules: {
            required: value => !!value || 'Väli peab olema täidetud',
            canNotBeEmpty: value => value !== null && value.length !== 0 || 'Väli peab olema täidetud'
        },
        headers: [
            {
                text: 'Toode',
                align: 'start',
                sortable: true,
                value: 'name',
            },
            { text: 'Grupp', value: 'groupId', sortable: true },
            { text: 'Toimingud', value: 'actions', align: 'end', sortable: false },
        ],
        editedIndex: -1,
        viewportHeight: 0,
        editedItem: {
            name: '',
            groupId: '',
            _id: ''
        },
        defaultItem: {
            name: '',
            groupId: '',
            _id: ''
        },
    }),

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Lisa uus toode' : 'Muuda toodet'
        },
        computedItems: {
            get() {
                // Getter returns the prop's value
                return this.items
            },
            set(newValue) {
                // Setter emits an event to inform the parent of the change
                this.$emit('update:items', newValue);
            },
        },
        sortedItems() {
            const items = [...this.computedItems].sort((a, b) => {
                if (a.groupId?.name === b.groupId?.name) {
                    return a.name.localeCompare(b.name);
                }
                return a.groupId?.name.localeCompare(b.groupId?.name);
            })

            if (!this.search) {
                return items;
            }
            const search = this.search.toLowerCase();
            return items
                .filter(item => item.name.toLowerCase().includes(search) || item.groupId?.name.toLowerCase().includes(search));
        }
    },

    created() {
        this.updateViewportHeight();
        window.addEventListener('resize', this.updateViewportHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateViewportHeight);
    },

    methods: {
        removeGroupOrderTag,
        updateViewportHeight() {
            this.viewportHeight = window.innerHeight - 48 - 38 - 48;
        },

        openCreateModal() {
            this.dialog = true
        },

        async save () {
            this.isCreateUpdateInProgress = true
            if (this.editedIndex > -1) {
                await this.updateProduct()
            } else {
                await this.createProduct()
            }
            this.close()
        },

        async createProduct () {
            await api.createProduct({
                name: this.editedItem.name,
                groupId: this.editedItem.groupId
            })

            this.$notify({
                text: 'Toode edukalt lisatud!',
                type: 'success'
            })

            this.computedItems.push({
                name: this.editedItem.name,
                groupId: {
                    _id: this.editedItem.groupId,
                    name: this.groups.find(group => group._id === this.editedItem.groupId).name
                }
            })
        },

        async updateProduct () {
            await api.updateProduct(
                this.editedItem._id, {
                    name: this.editedItem.name,
                    groupId: this.editedItem.groupId
                })

            this.$notify({
                text: 'Toode edukalt muudetud!',
                type: 'success'
            })
            Object.assign(this.computedItems[this.editedIndex], {
                _id: this.editedItem._id,
                name: this.editedItem.name,
                groupId: {
                    _id: this.editedItem.groupId,
                    name: this.groups.find(group => group._id === this.editedItem.groupId).name
                }
            })
        },

        editItem (item) {
            this.editedIndex = this.computedItems.indexOf(item)
            this.editedItem = Object.assign({}, {
                ...item,
                groupId: item.groupId?._id
            })
            this.dialog = true
        },

        deleteItem (item) {
            this.editedIndex = this.computedItems.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        async deleteItemConfirm () {
            this.isDeleteInProgress = true
            await this.deleteProduct()

            this.computedItems.splice(this.editedIndex, 1)

            this.$notify({
                title: 'Toode edukalt kustutatud!',
                type: 'success'
            })

            this.closeDelete()
        },

        async deleteProduct () {
            return await api.deleteProduct(this.editedItem._id)
        },

        closeDelete () {
            this.dialogDelete = false
            this.isDeleteInProgress = false

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        close () {
            this.isCreateUpdateInProgress = false
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
    },
}
</script>

<style scoped lang="scss">

</style>
