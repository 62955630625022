<template>
    <v-row
        v-if="!isDataFetchInProgress"
        dense
        class="pa-3"
        justify="center"
    >
        <v-col
            cols="12"
            style="max-width: 1600px"
        >
            <v-card outlined>
                <ProductsTable
                    :items="products"
                    :groups="groups"
                ></ProductsTable>
                <v-divider></v-divider>
            </v-card>
        </v-col>
    </v-row>
    <DataFetchLoader v-else></DataFetchLoader>
</template>

<script>
import api from "@/api";
import DataFetchLoader from "@/views/DataFetchLoader.vue";
import ProductsTable from "@/views/products/ProductsTable.vue";

export default {
    name: "ProductsManagement",
    components: { ProductsTable, DataFetchLoader },

    data () {
        return {
            products: [],
            groups: [],
            isDataFetchInProgress: false
        }
    },

    async mounted () {
        this.isDataFetchInProgress = true
        this.groups = (await api.fetchGroups()).response
        this.products = await this.fetchProducts().finally(() => {
            this.isDataFetchInProgress = false
        })
    },

    methods: {
        async fetchProducts () {
            return (await api.getProductsForManagement()).response
        }
    }
}
</script>

<style scoped lang="scss">

</style>
